import axios from "axios";

// Base reports
export const ListTransactionReportsApiService = (filters) => {
  const queryParams = new URLSearchParams(filters).toString();
  return axios.get(`subscriptions/transations?${queryParams}`);
};

export const ListSubscriptionReportsApiService = (params = {}) => {
  return axios.get(`subscriptions`, {
    params,
  });
};

// User Reports
export const ListSubscriptionCoursesApiService = (userId, subscriptionId) => {
  if (subscriptionId) {
    return axios.get(
      `reports/users/${userId}/products?subscription_id=${subscriptionId}`
    );
  } else {
    return axios.get(`reports/users/${userId}/products?free=${1}`);
  }
};

export const ListSubscriptionMockTestsApiService = (userId, subscriptionId) => {
  if (subscriptionId) {
    return axios.get(
      `reports/users/${userId}/tests?subscription_id=${subscriptionId}`
    );
  } else {
    return axios.get(`reports/users/${userId}/tests?free=${1}`);
  }
};

export const ListCoursesReportsApiService = (userId, courseId) => {
  return axios.get(`reports/users/${userId}/products/${courseId}/chapters`);
};

export const ListTestReportsApiService = (userId, testId) => {
  return axios.get(`reports/users/${userId}/tests/${testId}/attempts`);
};

export const ListLessonReportsApiService = (userId, chapterId, type) => {
  return axios.get(
    `reports/users/${userId}/chapters/${chapterId}/lessons?type=${type}&report=1`
  );
};

export const ListExcerciseReportsApiService = (userId, chapterId) => {
  return axios.get(
    `reports/users/${userId}/tests?chapter_id=${chapterId}?report=1`
  );
};

// MockTest Reports
export const ListAllMockTestApiService = (params) => {
  return axios.get(`reports/tests`, { params });
};

export const ListAllMockTestDetailsApiService = (testId, attempt) => {
  return axios.get(`reports/tests/${testId}?attempt=${attempt}`);
};

export const ListAllMockTestQuestionApiService = (testId, attempt, params) => {
  return axios.get(`reports/tests/${testId}/questions?attempt=${attempt}`, {
    params,
  });
};
export const ListAllLearnersInMockTestApiService = (
  testId,
  attempt,
  params
) => {
  return axios.get(`reports/tests/${testId}/attempts?attempt=${attempt}`, {
    params,
  });
};
export const ListSubscriptionDailyChallegeReportApiService = (
  userId,
  subscriptionId
) => {
  if (subscriptionId) {
    return axios.get(
      `reports/users/${userId}/tests?daily_challenge=1&subscription_id=${subscriptionId}`
    );
  } else {
    return axios.get(`reports/users/${userId}/tests?daily_challenge=1`);
  }
};
// Export Reports

export const ListAllExportsApiService = (params) => {
  return axios.get(`/exports/details`, { params });
};

// Course Reports
export const ListAllCourseReportApiServiceV2 = (params) => {
  return axios.get(`/v2/products`, { params });
};

export const ListAllCourseReportApiService = (params) => {
  return axios.get(`/reports/products`, { params });
};

export const ListPublicCourse = (id, params) => {
  return axios.get(`/reports/products/${id}/public`, { params });
};

export const ExportPublicCourse = (id, params) => {
  return axios.get(`/reports/products/${id}/public/export`, {
    params,
  });
};

export const ListPrivateCourse = (id, params) => {
  return axios.get(`/reports/products/${id}/private`, { params });
};

export const ListPrivateBatch = (params) => {
  return axios.get(`/v2/segments/batches`, { params });
};

export const ExportPrivateCourse = (id, params) => {
  return axios.get(`/reports/products/${id}/private/export`, {
    params,
  });
};

export const ListReportOfCourseApiService = (courseId) => {
  return axios.get(`/reports/products/${courseId}`);
};

export const ListClientStorageData = (page) => {
  return axios.get(`reports/lessons/storage`, { params: page });
};

export const ListAllExerciseApiService = (params) => {
  return axios.get(`reports/exercises`, { params });
};

export const ListAllExerciseDetailsApiService = (excersiseId, attempt) => {
  return axios.get(`reports/tests/${excersiseId}?attempt=${attempt}`);
};

export const ListAllExerciseQuestionApiService = (
  excersiseId,
  attempt,
  params
) => {
  return axios.get(
    `reports/tests/${excersiseId}/questions?attempt=${attempt}`,
    {
      params,
    }
  );
};

//Mentor report
export const ListMentors = (filters) => {
  const queryParams = new URLSearchParams(filters).toString();
  return axios.get(`/faculties?${queryParams}`);
};

export const ListCourses = (filters) => {
  const queryParams = new URLSearchParams(filters).toString();
  return axios.get(`/v2/products?${queryParams}`);
};

export const mentorReport = (filters) => {
  const queryParams = new URLSearchParams(filters).toString();
  return axios.get(
    `/session-bookings/reports/session-analytics?${queryParams}`
  );
};

export const sessionReport = (filters) => {
  const queryParams = new URLSearchParams(filters).toString();
  return axios.get(`/session-bookings/reports/session-report?${queryParams}`);
};
