import axios from "axios";

export const ListFaculitysApiService = (data = {}) => {
  return axios.get(`faculties`, {
    params: {
      ...data,
      paginate: data.paginate || 0,
    },
  });
};

export const ListMentorSessions = (filters) => {
  const queryParams = new URLSearchParams(filters).toString();
  return axios.get(`/session-bookings?${queryParams}`);
};

export const MentorCancel = (id, data) => {
  return axios.post(`/session-bookings/${id}/cancel`, data);
};

export const GetFaculityApiService = (id) => {
  return axios.get(`faculties/${id}`);
};

export const CreateFaculityApiService = (data) => {
  return axios.post(`faculties`, data);
};

export const AddModelsToFaculityApiService = (id, data) => {
  return axios.post(`faculties/${id}/add-models`, data);
};

export const ListModelsOfFaculityApiService = (id) => {
  return axios.get(`faculties/${id}/models`);
};

export const DeleteModelsOfFaculityApiService = (id, data) => {
  return axios.post(`faculties/${id}/remove-models`, data);
};

export const UpdateFaculityApiService = (id, data) => {
  return axios.post(`faculties/${id}`, data);
};

export const ChangeFaculityStatusApiService = (id, data) => {
  return axios.post(`faculties/${id}/change-status`, data);
};

export const DeleteFaculityApiService = (id) => {
  return axios.delete(`faculties/${id}`);
};

/******************* Reviewable Questions api ******************************/

export const ListAllReviewableQuestionsInMockTestApiService = (
  id,
  params = {}
) => {
  return axios.get(`tests/${id}/reviewable-questions`, {
    params,
  });
};

export const SubmitReviewOfQuestionsInMockTestApiService = (id, data) => {
  return axios.post(`/tests/attempts/${id}/submit-mark`, data);
};

/******************* Discussions Api ******************************/

export const ListChaptersToFaculityApiService = (
  searchTerm,
  courseId,
  chapterId
) => {
  return axios.get(`chapters`, {
    params: {
      discussions: 1,
      ...(searchTerm && { name: searchTerm }),
      ...(courseId && { product_ids: courseId }),
      ...(chapterId && { chapter_id: chapterId }),
    },
  });
};

export const ListCommentsApiService = (chapterId) => {
  return axios.get(`chapters/${chapterId}/comments`);
};

export const SubmitCommentsApiService = (commentId, data) => {
  return axios.post(`comments/${commentId}/replies`, data);
};

export const EditCommentsApiService = (commentId, replyId, data) => {
  return axios.post(`comments/${commentId}/replies/${replyId}`, data);
};

export const RemoveCommentsReplyApiService = (commentId, replyId, data) => {
  return axios.delete(`comments/${commentId}/replies/${replyId}`);
};

export const RemoveCommentApiService = (chapterId, commentId) => {
  return axios.delete(`chapters/${chapterId}/comments/${commentId}`);
};
