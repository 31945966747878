import axios from "axios";

export const PreviewTestApiService = (data = {}) => {
  return axios.post(`tests/preview`, data);
};

export const PublishTestApiService = (data = {}) => {
  return axios.post(`tests/publish`, data);
};

export const ListTestsApiService = (data = {}) => {
  return axios.get(`tests`, {
    params: {
      paginate: data.paginate || 0,
      page: data.page || 1,
      per_page: data.per_page || 10,
      segment_id: data.segment_id || undefined,
    },
  });
};

export const ViewTestApiService = (id, params = {}) => {
  return axios.get(`tests/${id}`, {
    params: { ...params },
  });
};

export const ViewTestApiServiceView = (id, params = {}) => {
  return axios.get(`tests/${id}/view`, {
    params: { ...params },
  });
};

export const AddTestApiService = (data) => {
  return axios.post("tests", data);
};

export const UpdateTestApiService = (testId, data) => {
  return axios.post(`tests/${testId}`, data);
};

export const AddQuestionsToTestApiService = (testId, data) => {
  return axios.post(`tests/${testId}/add-questions`, data);
};

export const RemoveQuestionsToTestApiService = (testId, data) => {
  return axios.post(`tests/${testId}/remove-questions`, data);
};

export const ChangeTestQuestionOrderApiService = (params, data) => {
  const { id: testId } = params;
  return axios.post(`tests/${testId}/order-questions`, data);
};

export const GetTestSettingsApiService = (id, params = {}) => {
  return axios.get(`tests/${id}/view`, {
    params: { ...params },
  });
};
export const UpdateTestSettingsApiService = (id, data) => {
  return axios.post(`tests/${id}/update-settings`, data);
};

//question type ids
export const MockTestPrevNextIds = async (
  id,
  sectionId,
  page = 0,
  perPage = 0
) => {
  const response = await axios.get(
    `/tests/${id}/sections/${sectionId}/list-question-ids?page=${page}&per_page=${perPage}`
  );

  return response;
};

export const QuestionBankPrevNextIds = async (
  productId,
  questionType,
  difficulty,
  chapterId,
  page,
  perPage
) => {
  const response = await axios.get(
    `/questions/ids?page=${page}&per_page=${perPage}&product_id=${productId}&question_type=${
      questionType === "null" ? "" : questionType
    }&difficulty=${difficulty === "null" ? "" : difficulty}&chapter_id=${
      chapterId === "null" ? "" : chapterId
    }`
  );

  return response;
};
